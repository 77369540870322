import { Component, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PrepressTaskList, PrepressTaskListQuery, UploadedFile } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  dateFormat,
  downloadFileByBlob,
  showWarningConfirm,
  getFileName,
  isNullOrUndefinedForBaseType,
  messageErrors,
  getMinioFileName,
  debounce
} from '@/utils';
import {
  CloseStatusEnum,
  PageQueryPlanEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  PrepressTypeEnum
} from '@/resource/enum';
import { customerService, minePrepressChangeColorTaskService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { Message } from 'element-ui';
import PrepressTaskDetails from '../prepress-task-details/prepress-task-details.vue';
import { UploadFile } from '@/views/dialogs';
import { MinIOFile } from '@/api/minio';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { SortOptions } from '@/api/base';
import { cloneDeep } from 'lodash-es';
import { NormalSelectOptions } from '@/resource/model/common';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import BatchAddColorChangeSample from '../color-change-sample/batch-add-color-change-sample/batch-add-color-change-sample.vue';
@Component({
  components: { PrepressTaskDetails, UploadFile, BatchAddColorChangeSample }
})
export default class MineChangeColorTask extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<PrepressTaskList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'requiredDeliveryTime' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<PrepressTaskList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      width: '45px',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '230px',
      showOverflowTooltip: true,
      sortable: 'custom',
      fixed: true
    },
    // {
    //   prop: 'thumbnails',
    //   label: 'prepress.thumbnails',
    //   minWidth: '120px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'projectName',
      label: 'prepress.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'project.customer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as PrepressTaskList).visibleWidth} × ${(row as PrepressTaskList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as PrepressTaskList).finishWidth} × ${(row as PrepressTaskList).finishHeight}`;
      }
    },
    {
      prop: 'topPocketSize',
      label: 'prepress.topPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'bottomPocketSize',
      label: 'prepress.bottomPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'count',
      label: 'prepressTask.count',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'prepressSubmitCount',
      label: 'projectProduct.prepressSubmitCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'screenArea',
      label: 'prepressTask.screenArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'screenRate',
      label: 'prepressTask.screenRate',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'customerProductName',
      label: 'prepress.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'prepress.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'pointName',
    //   label: 'projectProduct.pointName',
    //   minWidth: '180px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'requiredDeliveryTime',
      label: 'prepress.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      sortable: 'custom',
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as PrepressTaskList).prepressCrafts) {
          return '--';
        }
        if ((row as PrepressTaskList).itemType === PrepressTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as PrepressTaskList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as PrepressTaskList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'prepress.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'prepress.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressStatus',
      label: 'prepress.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'makeStatus',
    //   label: 'prepressTask.proofingStatus',
    //   minWidth: '150px',
    //   showOverflowTooltip: true,
    //   formatter: (row: object): string => {
    //     if ((row as PrepressTaskList).itemType === PrepressTypeEnum.finalized) {
    //       return '--';
    //     }
    //     if (!(row as PrepressTaskList).makeStatus) {
    //       return translation('common.unKnownStatus');
    //     }
    //     return translation(`projectProductStatus.${ProjectProductStatusEnum[(row as PrepressTaskList).makeStatus]}`);
    //   }
    // },
    // {
    //   prop: 'confirmFlag',
    //   label: 'projectProduct.confirmFlag',
    //   minWidth: '150px',
    //   showOverflowTooltip: true,
    //   formatter: (row: Object): string => {
    //     return (row as PrepressTaskList).confirmFlag === 1 ? translation('common.yes') : translation('common.no');
    //   }
    // },
    {
      prop: 'makeUserName',
      label: 'prepress.makeUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'makeStateTime',
      label: 'prepress.makeStateTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).makeStateTime);
      }
    },
    {
      prop: 'createUserName',
      label: 'common.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'project.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).createTime);
      }
    },
    // {
    //   prop: 'makeStateTime',
    //   label: 'prepressTask.productionStartTime',
    //   minWidth: '180px',
    //   showOverflowTooltip: true,
    //   formatter: (row: Object): string => {
    //     return dateFormat((row as PrepressTaskList).makeStateTime);
    //   }
    // },
    {
      prop: 'attritionFlag',
      label: 'projectProduct.isConsumeRemake',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (isNullOrUndefinedForBaseType((row as PrepressTaskList).attritionFlag)) {
          return '--';
        }
        return translation(`common.${(row as PrepressTaskList).attritionFlag === 0 ? 'no' : 'yes'}`);
      }
    },
    {
      prop: 'dutyReason',
      label: 'consumed.consumedReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionCode',
      label: 'projectProduct.consumedCode',
      minWidth: '150',
      showOverflowTooltip: true
    }
  ];

  public currentCompleteItemType: PrepressTypeEnum = PrepressTypeEnum.changeColor;

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.cancelOrder',
      operationType: 'cancel',
      disabled: true,
      icon: 'el-icon-close',
      permissionCode: 'my:color:task:batchCancel',
      handleClick: this.batchCancel
    },
    {
      // 新建改色样
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.addChangeColorSample',
      operationType: 'addChangeColorSample',
      disabled: true,
      icon: 'el-icon-plus',
      permissionCode: 'color:change:manage:save',
      handleClick: this.batchAddChangeColorSample
    },
    {
      // 改色-提交审稿
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.submitReview',
      operationType: 'changeColorConfirm',
      icon: 'el-icon-check',
      disabled: true,
      permissionCode: 'my:color:task:submitColorArtisConfirm',
      handleClick: this.changeColorConfirmation
    },
    {
      // 改色-撤销审稿
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.cancelReview',
      operationType: 'cancelReview',
      icon: 'el-icon-close',
      disabled: true,
      permissionCode: 'my:color:task:cancelColorArtisConfirm',
      handleClick: this.cancelColorArtisConfirm
    },
    // {
    //   // 改色-完成归档
    //   type: 'primary',
    //   slot: 'start',
    //   label: 'prepressTask.archiveComplete',
    //   operationType: 'archiveComplete',
    //   disabled: true,
    //   icon: 'el-icon-check',
    //   permissionCode: 'my:task:colorArtisComplete',
    //   handleClick: this.changeColorComplete
    // },

    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'my:color:task:export',
      handleClick: this.export
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<PrepressTaskListQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'prepress.itemCode',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'prepress.platformProduct',
      option: {
        placeholder: 'consumed.inputPlatformProduct'
      }
    },
    {
      type: 'Select',
      field: 'prepressStatusList',
      label: 'deliveryNoticeList.status',
      option: {
        placeholder: 'deliveryNoticeList.selectStatus',
        multiple: 'multiple'
      },
      optionData: this.getStatus
    },
    {
      type: 'Select',
      field: 'urgentOrderFlag',
      label: 'order.urgentOrder',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Input',
      field: 'prepressPicture',
      label: 'prepress.prepressPicture',
      option: {
        placeholder: 'prepress.inputPrepressPicture'
      }
    },

    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'prepressTask.deliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'project.customer',
      option: {
        placeholder: 'project.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.orderCreator',
      option: {
        placeholder: 'project.selectCreator'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'orderCreateTime',
      label: 'order.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Input',
      field: 'visibleWidth',
      label: 'projectProduct.visibleWidth',
      option: {
        placeholder: 'customerPoint.inputVisibleWidth'
      }
    },
    {
      type: 'Input',
      field: 'visibleHeight',
      label: 'projectProduct.visibleHeight',
      option: {
        placeholder: 'customerPoint.inputVisibleHeight'
      }
    },
    {
      type: 'Input',
      field: 'finishWidth',
      label: 'projectProduct.finishWidth',
      option: {
        placeholder: 'customerPoint.inputFinishWidth'
      }
    },
    {
      type: 'Input',
      field: 'finishHeight',
      label: 'projectProduct.finishHeight',
      option: {
        placeholder: 'customerPoint.inputFinishHeight'
      }
    },
    {
      type: 'Select',
      field: 'isOverdue',
      label: 'projectProduct.isOverdue',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.overdue'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'closeStatus',
      label: 'projectProduct.closeStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('closeStatus.normal'),
            value: 0
          },
          {
            label: translation('closeStatus.closed'),
            value: 1
          }
        ];
      }
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.mineChangeColorTask;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];
  /**
   * 控制提交确稿显隐
   */
  public artworkConfirmationVisible = false;

  /**
   * 控制制作完成dialog显隐
   */
  public artworkCompleteVisible = false;

  /**
   * 控制详情dialog显隐
   */
  public detailsVisible = false;
  /**
   * 控制批量修改改色样
   */
  public batchChangeColorVisible = false;

  public editList: Array<{ id: number; customerName: string }> = [];
  /**
   * 印前单据主键
   */
  public prepressId: number | null = null;

  /**
   * 当前选中的项次类型
   */
  public currentItemType: PrepressTypeEnum = PrepressTypeEnum.changeColor;

  /**
   * 印前状态单选组绑定的数据
   */
  public readonly prepresStatusForMineTask = {
    /**
     * 制作中
     */
    inProduction: PrepressStatusEnum.inProduction,
    /**
     * 待确搞
     */
    waitForConfirm: PrepressStatusEnum.waitForConfirm,
    /**
     *  待完成
     */
    waitComplete: PrepressStatusEnum.waitComplete
  };

  /**
   * 当前选中的印前状态
   */
  public currentStatus: 'all' | PrepressStatusEnum = 'all';

  public selectedRows: Array<PrepressTaskList> = [];

  public queryForm: Partial<PrepressTaskListQuery> = {};

  private defaultQueryForm: Partial<PrepressTaskListQuery> = {
    createEndTime: undefined,
    createStartTime: undefined,
    createUserName: '',
    customerId: undefined,
    finishHeight: undefined,
    finishWidth: undefined,
    itemCode: '',
    orderCreateTime: [],
    platformProductName: '',
    prepressPicture: '',
    prepressStatusList: [],
    projectName: '',
    requiredDeliveryEndTime: undefined,
    requiredDeliveryStartTime: undefined,
    requiredDeliveryTime: [],
    urgentOrderFlag: undefined,
    visibleHeight: undefined,
    visibleWidth: undefined,
    isOverdue: undefined,
    closeStatus: undefined
  };

  private sortOptions: SortOptions<PrepressTaskList> = this.tableOption.defaultSort!;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public uploadFiles: Array<{ id: number; itemCode: string }> = [];

  public get area(): string {
    return this.selectedRows
      .reduce((res, curr) => res + (curr.finishWidth / 1000) * (curr.finishHeight / 1000) * curr.count, 0)
      .toFixed(3);
  }
  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'mine-change-color-task');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    (this.$refs.mineTaskTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<PrepressTaskList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(project: PrepressTaskList): void {
    this.detailsVisible = true;
    this.prepressId = project.id;
  }

  public handleSelectionChange(selectedData: Array<PrepressTaskList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusClass(task: PrepressTaskList): string {
    switch (task.prepressStatus) {
      case PrepressStatusEnum.waitForProduction:
        return 'danger-dot';
      case PrepressStatusEnum.inProduction:
        return 'primary-dot';
      case PrepressStatusEnum.waitForConfirm:
        return 'warning-dot';
      case PrepressStatusEnum.waitComplete:
        return 'warning-dot';
      default:
        return 'info-dot';
    }
  }

  public getStatusName(task: PrepressTaskList): string {
    if (!task.prepressStatus) {
      return translation('common.unKnownStatus');
    }
    return `prepressStatus.${PrepressStatusEnum[task.prepressStatus]}`;
  }

  /**
   * 上传文件之前检查文件名是否符合业务约定的格式
   * @param toUploadfiles 待上传的文件
   */
  public beforeUpload(toUploadfiles: Array<MinIOFile>): Promise<Array<MinIOFile>> {
    const matchedFiles: Array<MinIOFile> = [];
    const unMatchedFileNames: Array<string> = [];
    const repeatFileNames: Array<string> = [];
    for (const file of toUploadfiles) {
      file.originFile = new File([file.originFile!], `_t_${file.name}`);
      if (!this.verifyFileName(this.effectiveFileName(file.name))) {
        unMatchedFileNames.push(file.name);
        continue;
      }
      // 去除重复文件
      if (matchedFiles.filter(x => this.effectiveFileName(x.name) === this.effectiveFileName(file.name)).length > 0) {
        repeatFileNames.push(file.name);
        continue;
      }
      matchedFiles.push(file);
    }
    // 如果项次出现重复文件
    if (repeatFileNames.length > 0) {
      this.$notify({
        title: translation('tip.tipInfo'),
        dangerouslyUseHTMLString: true,
        message: `${repeatFileNames.join('</br>')}</br>${translation('prepressTask.fileRepeat')}`,
        type: 'warning'
      });
      return Promise.reject();
    }
    // 全部符合规则
    if (unMatchedFileNames.length === 0) {
      return Promise.resolve(toUploadfiles);
    }
    // 文件全部未通过验证，直接取消
    if (matchedFiles.length === 0) {
      Message.warning(translation('prepressTask.noFileToUpload'));
      return Promise.resolve([]);
    }
    // 部分文件不匹配，提示用户选择，是否继续上传
    const message = `${translation('prepressTask.fileName')}
    ${unMatchedFileNames.join('，')}
    ${translation('prepressTask.unMatchedItemCode')}`;
    return showWarningConfirm(message)
      .then(() => {
        return Promise.resolve(matchedFiles);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
  /**
   * 完稿（提交审稿）文上传文件之前检查文件名是否符合业务约定的格式
   * @param toUploadfiles 待上传的文件
   */
  public async beforeUploadConfirm(toUploadfiles: Array<MinIOFile>): Promise<Array<MinIOFile>> {
    const matchedFiles: Array<String> = [];
    const unMatchedFileNames: Array<string> = [];
    const repeatFileNames: Array<string> = [];
    this.uploadFiles = [];
    for (const file of toUploadfiles) {
      if (!/-/.test(this.effectiveFileName(file.name))) {
        unMatchedFileNames.push(file.name);
        continue;
      }
      // 去除重复文件
      if (matchedFiles.includes(this.effectiveFileName(file.name))) {
        repeatFileNames.push(file.name);
        continue;
      }
      matchedFiles.push(this.effectiveFileName(file.name));
    }
    // 如果项次出现重复文件
    if (repeatFileNames.length > 0) {
      this.$notify({
        title: translation('tip.tipInfo'),
        dangerouslyUseHTMLString: true,
        message: `${repeatFileNames.join('</br>')}</br>${translation('prepressTask.fileAdded')}`,
        type: 'warning'
      });
      return Promise.reject();
    }

    // 文件全部未通过验证，直接取消
    if (matchedFiles.length === 0) {
      Message.warning(translation('prepressTask.noFileToUpload'));
      return Promise.resolve([]);
    }

    // 校验文件
    const allow = await this.checkConfirmItem(toUploadfiles);
    if (allow) {
      return Promise.resolve(toUploadfiles);
    } else {
      return Promise.reject([]);
    }
  }

  public async checkConfirmItem(toUploadfiles: Array<MinIOFile>): Promise<boolean> {
    const fileNamelist: Array<string> = [];
    for (const file of toUploadfiles) {
      fileNamelist.push('v3PO' + this.effectiveFileName(file.name));
    }
    return new Promise((resolve, reject) => {
      minePrepressChangeColorTaskService
        .checkConfirmItem(fileNamelist)
        .then(res => {
          this.uploadFiles = res.data;
          resolve(true);
        })
        .catch(error => {
          messageErrors(error);
          reject(false);
        });
    });
  }

  /**
   * 制作完成上传文件成功的回调
   * @param paths 完成文件的minio路径数组
   */
  public handleUploadedCompleteFile(files: Array<UploadedFile>): void {
    const fileParams: Array<{ id: number; fileId: number }> = this.processingFilePath(
      files.map(x => {
        return { fileName: x.name, id: x.id }!;
      })
    );
    if (fileParams.length === 0) {
      Message.warning(translation('prepressTask.noFileToUpload'));
      (this.$refs.artworkComplete as UploadFile).setLoading(false);
      return;
    }
    this.artworkComplete(fileParams);
  }

  /**
   * 完稿-提交审稿
   * @param paths 路径
   * @returns
   */
  public artworkConfirmation(files: Array<UploadedFile>): void {
    const fileParams: Array<{ id: number; fileId: number }> = this.processingFilePath(
      files.map(x => {
        return { fileName: x.name, id: x.id }!;
      }),
      true
    );
    if (fileParams.length === 0) {
      Message.warning(translation('prepressTask.noFileToUpload'));
      (this.$refs.artworkComplete as UploadFile).setLoading(false);
      return;
    }

    minePrepressChangeColorTaskService
      .artworkConfirmation(fileParams)
      .then(() => {
        this.artworkConfirmationVisible = false;
        this.reloadData();
        Message.success(translation('dialog.uploadSuccess'));
      })
      .catch(error => {
        (this.$refs.artworkConfirmation as UploadFile).setLoading(false);
        messageError(error);
      });
  }

  public reloadTableAndOperationOpts(): void {
    (this.$refs.mineTaskTable as OsTable).clearSelection();
    this.loadData();
    this.operationOptions = [...this.operationOptions];
  }

  /**
   * 获取状态
   */
  public get getStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PrepressStatusEnum) {
      if (isNaN(Number(key)) && [3, 4].includes(Number(PrepressStatusEnum[key]))) {
        options.push({
          label: translation(`prepressStatus.${key}`),
          value: PrepressStatusEnum[key]
        });
      }
    }
    return options;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    minePrepressChangeColorTaskService
      .getList(this.handleQuery(), this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data.map(x => {
          x.screenArea = ((x.finishWidth / 1000) * (x.finishHeight / 1000)).toFixed(6);
          x.screenRate = (x.visibleWidth / x.visibleHeight).toFixed(3);
          x.itemType = this.currentCompleteItemType;
          return x;
        });
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private handleQuery(): PrepressTaskListQuery {
    const res = cloneDeep(this.queryForm) as PrepressTaskListQuery;
    if (this.currentItemType) {
      res.itemType = this.currentItemType;
    }
    return res;
  }
  private batchAddChangeColorSample(): void {
    this.editList = this.selectedRows
      .filter(x =>
        [PrepressStatusEnum.inProduction, PrepressStatusEnum.waitForConfirm, PrepressStatusEnum.waitComplete].includes(
          x.prepressStatus
        )
      )
      .map(x => {
        return {
          id: x.id,
          customerName: x.customerName
        };
      });
    if (this.editList.length === 0) {
      Message.warning(translation('prepressTask.selectMatchedRequire'));
      return;
    }
    this.batchChangeColorVisible = true;
  }
  /**
   * 新建改色样
   */
  private addChangeColorSample(): void {
    const ids = this.selectedRows
      .filter(x =>
        [PrepressStatusEnum.inProduction, PrepressStatusEnum.waitForConfirm, PrepressStatusEnum.waitComplete].includes(
          x.prepressStatus
        )
      )
      .map(x => {
        return {
          id: x.id,
          customerName: x.customerName
        };
      });
    if (ids.length === 0) {
      Message.warning(translation('prepressTask.selectMatchedRequire'));
      return;
    }
    this.$router.push({
      path: 'add-change-color-sample',
      query: {
        id: ids[0].id.toString(),
        type: 'add',
        customerName: ids[0].customerName
      }
    });
  }

  /**
   * 制作完成按钮点击事件
   */
  private openArtworkCompleteDialog(): void {
    // if (this.selectedRows.find(x => x.prepressStatus !== PrepressStatusEnum.waitComplete)) {
    //   Message.warning(translation('prepressTask.unMatchedWaitCompleteStatus'));
    //   return;
    // }
    this.$confirm(translation('prepressTask.isNeedUploadFile'), translation('tip.confirmTitle'), {
      distinguishCancelAndClose: true,
      confirmButtonText: translation('prepressTask.need'),
      cancelButtonText: translation('prepressTask.notNeed')
    })
      .then(() => {
        this.artworkCompleteVisible = true;
      })
      .catch(action => {
        if (action === 'cancel') {
          const completeParams: Array<{ id: number }> = this.selectedRows
            .filter(x => x.prepressStatus === PrepressStatusEnum.waitComplete || x.confirmFlag === 0)
            .map(x => {
              return {
                id: x.id
              };
            });
          this.artworkComplete(completeParams);
        }
      });
  }

  /**
   * 制作完成
   * @param paths 路径
   * @returns
   */
  private async artworkComplete(completeParams: Array<{ id: number; file?: string }>): Promise<void> {
    try {
      await minePrepressChangeColorTaskService.changeColorComplete(completeParams);
      this.artworkCompleteVisible = false;
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      (this.$refs.artworkComplete as UploadFile).setLoading(false);
      messageError(error);
    }
  }

  /**
   * 改色-提交审稿
   */
  private async changeColorConfirmation(): Promise<void> {
    // try {
    //   await showWarningConfirm(translation('prepressTask.submitReviewConfirmTip'));
    //   this.tableOption.loading = true;
    //   // 处理参数
    //   const params = this.selectedRows
    //     .filter(
    //       x =>
    //         x.confirmFlag === 1 &&
    //         x.prepressStatus === PrepressStatusEnum.inProduction &&
    //         (x.makeStatus === ProjectProductStatusEnum.waitConfirm || x.makeStatus === ProjectProductStatusEnum.new)
    //     )
    //     .map(x => x.id);
    //   if (params.length === 0) {
    //     Message.warning(translation('prepressTask.unMatchedRequire'));
    //     return;
    //   }

    //   await minePrepressChangeColorTaskService.changeColorConfirmation(params);
    //   this.reloadData();
    //   Message.success(translation('operationRes.submitSuccess'));
    // } catch (error) {
    //   if (error !== 'cancel') messageError(error);
    // } finally {
    //   this.tableOption.loading = false;
    // }
    if (
      this.selectedRows.find(
        x => x.prepressStatus !== PrepressStatusEnum.inProduction || x.closeStatus !== CloseStatusEnum.normal
      )
    ) {
      Message.warning(translation('prepressTask.unMatchedRequire'));
      return;
    }
    const ids = this.selectedRows.map(x => x.id!);
    try {
      this.tableOption.loading = true;
      minePrepressChangeColorTaskService
        .submitColorArtisConfirm(ids)
        .then(() => {
          Message.success(translation('operationRes.operationSuccess'));
          this.reloadData();
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {
          this.tableOption.loading = false;
        });
    } catch (error) {
      messageErrors(error);
    }
  }
  /**
   * 改色-撤销审稿
   */
  private async cancelColorArtisConfirm(): Promise<void> {
    if (
      this.selectedRows.find(
        x => x.prepressStatus !== PrepressStatusEnum.waitForConfirm || x.closeStatus !== CloseStatusEnum.normal
      )
    ) {
      Message.warning(translation('prepressTask.unMatchedRequire'));
      return;
    }
    const ids = this.selectedRows.map(x => x.id!);
    try {
      this.tableOption.loading = true;
      minePrepressChangeColorTaskService
        .cancelColorArtisConfirm(ids)
        .then(() => {
          Message.success(translation('operationRes.operationSuccess'));
          this.reloadData();
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {
          this.tableOption.loading = false;
        });
    } catch (error) {
      messageErrors(error);
    }
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const ids = this.selectedRows.map(x => x.id);
      const blob = await minePrepressChangeColorTaskService.export(this.queryForm, ids);
      downloadFileByBlob(`${translation('prepressTask.exportMineTask')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.orderCreateTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleRequiredDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  /**
   * 处理待上传的文件路径
   * @param paths 文件路径
   * @returns
   */
  private processingFilePath(
    files: Array<{ id: number; fileName: string }>,
    isCheckUpload: boolean = false
  ): Array<{ id: number; fileId: number }> {
    const fileRes: Array<{ id: number; fileId: number }> = [];
    for (const file of files) {
      // 按照约定，上传到minio的文件名会使用 时间戳_文件名 这种方式拼接
      // const fileName1 = getMinioFileName(file.fileName);

      // 在提交时已经把与勾选的印前任务匹配不上的文件过滤掉了
      // // 为了防止意外情况，在这里再过滤一次
      // if (!this.verifyFileName(this.effectiveFileName(fileName))) {
      //   continue;
      // }

      let prepressTask;
      if (isCheckUpload) {
        const itemCode = `v3PO${this.effectiveFileName(file.fileName)}`;
        prepressTask = this.uploadFiles.find(x => x.itemCode === itemCode)!;
      } else {
        const fileNameStr = getMinioFileName(file.fileName);
        const itemCode = `v3PO${this.effectiveFileName(fileNameStr)}`;
        prepressTask = this.selectedRows.find(x => x.itemCode === itemCode)!;
      }

      fileRes.push({
        id: prepressTask.id,
        fileId: file.id
      });
    }
    return fileRes;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<PrepressTaskList>): void {
    this.operationOptions.forEach(x => {
      if (!['export', 'confirm'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  /**
   * 验证文件名是否与选中的项次编号匹配
   * @param fileName
   * @returns
   */
  private verifyFileName(fileName: string): boolean {
    const itemCode = `v3PO${fileName}`;
    return !!this.selectedRows.find(x => x.itemCode === itemCode);
  }

  /**
   * 有效文件名为文件名的前15位，该逻辑为业务约定
   * @param file
   * @returns
   */
  private effectiveFileName(filePath: string): string {
    return getFileName(filePath).slice(0, 15);
  }

  private async batchCancel(): Promise<void> {
    // 只允许取消印前状态为制作中、制作状态为新建、改稿数量为0的项次
    const ids = this.selectedRows
      .filter(x => x.prepressStatus === PrepressStatusEnum.inProduction)
      .filter(x => x.modifyCount === 1)
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('prepressTask.unMatchedRequire'));
      return;
    }
    try {
      await showWarningConfirm(translation('prepress.cancelTip'));
      this.tableOption.loading = true;
      await minePrepressChangeColorTaskService.batchCancelTask(ids);
      Message.success(translation('operationRes.operationSuccess'));
      this.reloadData();
    } catch (error) {
      if (error !== 'cancel') {
        messageErrors(error);
      }
    } finally {
      this.tableOption.loading = false;
    }
  }
}
